@import "/src/constants/colors";

.Button {
    background-color: $background;
    border-top: 2px solid #eeeeee;
    border-left: 2px solid #eeeeee;
    border-bottom: 2px solid #131313;
    border-right: 2px solid #131313;
    min-width: 100px;
    padding: 4px 1px;
    margin-bottom: 8px;
    margin-top: 2px;
}

.Button.submit {
    outline: 2px solid #131313;
}

.Button:active {
    border-top: 2px solid #131313;
    border-left: 2px solid #131313;
    border-bottom: 2px solid #eeeeee;
    border-right: 2px solid #eeeeee;
}

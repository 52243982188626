@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap");

$bg: #010101;
$primaryColor: #1cffff;
$secondaryColor: #797575;

.Container {
    width: 100%;
    height: 100%;
    font-family: "Poppins", sans-serif;
    overflow-y: scroll;
}

.Container * {
    font-family: inherit;
    color: white;
}

.Particles {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $bg;
    z-index: -1;
    top: 0;
    left: 0;
}

.primary {
    color: $primaryColor;
}

.secondary {
    color: $secondaryColor;
    font-size: 0.9rem;
}

h2,
h3 {
    margin: 0;
    font-size: 1.6rem;
    line-height: 1;
    margin-bottom: 8px;
}

h3 {
    font-size: 1.2rem;
}

.tiny {
    font-size: 0.55em;
    color: $secondaryColor;
}

.Main {
    width: 80%;
    background-color: $bg;
    border-radius: 50px;
    border: 3px solid #1b1b1b;
    margin: 10vh auto;
    display: flex;
    flex-direction: column;
    color: white;
    overflow: hidden;
    z-index: 2;
}

section {
    width: 80%;
    align-self: center;
    padding-top: 60px;
    color: white;
}

.Head {
    position: relative;
    margin-top: 10px;
    min-height: 400px;
}

.NameContainer {
    overflow: hidden;
    position: absolute;
    z-index: 2;
}

.NameContainer video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

.NameContainer .name {
    font-size: 72px;
    font-weight: bold;
    line-height: 72px;
    background-color: $bg;
    text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
    mix-blend-mode: multiply;
}

.ProfilePic {
    position: absolute;
    top: 15%;
    right: 5%;
    border: 5px solid transparent; /* space for the border */
    background: center center;
    z-index: 1;
}

.ProfilePic img {
    width: 250px;
    height: 250px;
    border-radius: 125px;
    object-fit: cover;
    transition: 0.5s all;
}

.ProfilePic::before {
    position: absolute;
    content: url("../../assets/misc/blob.svg");
    width: 250px;
    height: 250px;
    transform: scale(2.5) translateY(-20%) translateX(22%);
    z-index: -3;
    pointer-events: none;
}

.ProfilePic img:hover {
    transform: scale(1.15) rotate(5deg) perspective(10px);
}

.ProfilePic:hover {
    transition: 0.5s all;
    --b: 5px; /* thickness of the border */
    --c: #1cffff; /* color of the border */
    --w: 20px; /* width of border */

    background: linear-gradient(var(--c), var(--c)) top left, linear-gradient(var(--c), var(--c)) top left,
        linear-gradient(var(--c), var(--c)) bottom right, linear-gradient(var(--c), var(--c)) bottom right;
    background-size: var(--b) var(--w), var(--w) var(--b);
    background-origin: border-box;
    background-repeat: no-repeat;
}

.Contact {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 30px;
    padding-bottom: 50px;

    a {
        text-decoration: none;
    }

    a h3 {
        transition: 0.5s all;
        text-decoration: none;
    }

    a:hover h3 {
        color: $primaryColor !important;
    }
}

.Socials {
    display: flex;
    align-items: center;
    gap: 15px;
    align-self: center;
}

.ModalProjectImage {
    width: 100%;
    border-radius: 10px;
    margin: 10px 0;
    background-color: #f2f2f2;
}

@media (max-width: 1200px) {
    .Main {
        width: calc(100% - 10px);
        border-radius: 35px;
    }

    section {
        width: 87.5%;
    }

    .ProfilePic {
        position: relative;
        display: flex;
        justify-content: center;
        margin-top: 20px;
        right: unset;
    }

    .ProfilePic::before {
        width: 200px;
        height: 200px;
        transform: scale(2) translateY(-15%) translateX(22%);
    }

    .Contact {
        gap: 20px;
    }
}

_::-webkit-full-page-media,
_:future,
:root .NameContainer video {
    display: none;
}

_::-webkit-full-page-media,
_:future,
:root .ProfilePic:hover {
    background: unset;
}

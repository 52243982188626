$primaryColor: #1cffff;

.GlowIcon {
    a {
        width: 55px;
        height: 55px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #1b1b1b;
        border-radius: 50%;
        font-size: 30px;
        color: #797575;
        border: 3px solid transparent;
        transition: 0.3s all;

        svg {
            padding-bottom: 3px;
            stroke: $primaryColor;
            transition: 3.5s all ease-out;
        }
    }

    a:hover {
        color: $primaryColor;
        box-shadow: 0 0 15px $primaryColor;
        text-shadow: 0 0 10px $primaryColor;
        border: 3px solid $primaryColor;
    }

    a:hover svg {
        -webkit-filter: drop-shadow(0 0 10px $primaryColor);
        filter: drop-shadow(0 0 10px $primaryColor);
        transition: 0.4s all;
    }
}

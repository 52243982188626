.Preview {
    max-width: 100%;
    width: 100%;
    height: 400px;
    border-top: 3px solid #414141;
    border-left: 3px solid #414141;
    border-right: 3px solid #ebebeb;
    border-bottom: 3px solid #ebebeb;
}

.Preview img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.Preview div {
    width: 100%;
    height: 100%;
}

.Wallpapers {
    flex: 1;
    background-color: #fff;
    border-top: 2px solid #1c1c1c;
    border-left: 2px solid #1c1c1c;
    border-bottom: 2px solid #e2e2e2;
    border-right: 2px solid #e2e2e2;
}

.WallpaperRow {
    cursor: pointer;
}

.Colors {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 5px;
    grid-row-gap: 5px;
}

.Color {
    width: 25px;
    height: 25px;
    cursor: pointer;
    border-top: 3px solid #414141;
    border-left: 3px solid #414141;
    border-right: 3px solid #ebebeb;
    border-bottom: 3px solid #ebebeb;
}

.ProgramIcon {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60px;
    text-align: center;
    margin: 10px;
    padding: 4px;
    color: white;
}

.ProgramIcon img {
    height: 40px;
    width: 40px;
}

.ProgramIcon span {
    color: inherit;
    padding: 2px 0;
}

.ProgramIcon:focus {
    filter: sepia(100%) hue-rotate(190deg) saturate(500%);
    image-rendering: pixelated;
}

.ProgramIcon:focus > span {
    background-color: blue;
    border: 1px dotted white;
    outline: 1px dotted black;
}

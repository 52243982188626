.Container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
}

.TopContainer {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.MainContainer {
    display: flex;
    flex-wrap: wrap;
}

.Directories {
    flex: 1;
    background-color: #fff;
    border-top: 2px solid #1c1c1c;
    border-left: 2px solid #1c1c1c;
    border-bottom: 2px solid #e2e2e2;
    border-right: 2px solid #e2e2e2;
    min-height: 250px;
    max-height: 250px;
    overflow-y: scroll;
}

.SideContainer {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
}

.DirectoryRow {
    display: flex;
    align-items: center;
    margin: 5px 10px;
    cursor: pointer;
}

.DirectoryRow img {
    height: 20px;
    margin-right: 6px;
}

.BottomContainer {
    margin-top: 10px;
}
.Card {
    position: relative;
    flex-direction: row-reverse;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
    background-color: #1b1b1b;
    padding: 20px;
    margin: 30px 0;
    border-radius: 25px;
    transition-property: box-shadow, transform;
    transition-duration: 0.4s;
    transition-timing-function: ease;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
}

.Card:hover {
    -webkit-box-shadow: 0px 4px 21px 1px rgba(255, 255, 255, 0.47);
    box-shadow: 0px 4px 21px 1px rgba(255, 255, 255, 0.47);
    transform: translateY(-2px);
}

.Icon {
    height: 125px;
    width: 125px;
    border-radius: 15px;
}

.DescContainer {
    flex: 1;
    margin-left: 10;
}

.Name {
    display: block;
    font-weight: bold;
}

.Tech {
    color: #1cffff !important;
    font-weight: lighter;
    font-size: 0.9em;
    text-align: center;
}

.Desc {
    font-size: 0.9em;
}

.Screenshots {
    display: flex;
    align-items: flex-start;
    height: 150px;
    background-color: #fff;
    border-radius: 25px;
    transition: 0.3s all;
    padding: 10px 5px;
    max-width: 100%;
}

.Screenshots:hover {
    transform: scale(2);
    transform-origin: bottom right;
    margin-left: 0;
}

.Links:hover + .Screenshots {
    transform: scale(none);
}

.Screenshots img {
    margin: 0 10px;
    height: 100%;
    border-radius: 10px;
    min-width: 70px;
    background-color: #f2f2f2;
    font-size: 0.6em;
    color: gray;
}

.Links {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    padding-left: 15px;
    z-index: 2;
    margin-top: 10px;
}

.Links a {
    stroke: #fff;
    cursor: pointer;
    padding: 2px 0;
}

.Links a svg {
    stroke: inherit;
}

.Links div {
    cursor: pointer;
}

@media (max-width: 1200px) {
    .Card {
        flex-direction: column;
        flex-direction: column-reverse;
        gap: 10px;
    }

    .Icon {
        height: 100px;
        width: 100px;
        align-self: center;
    }

    .DescContainer {
        margin-left: 0;
        display: flex;
        flex-direction: column;
    }

    .Name,
    .Tech {
        align-self: center;
        text-align: center;
    }

    .Screenshots {
        align-self: center;
        width: 100%;
        justify-content: space-evenly;
        padding: 10px 0;
    }

    .Screenshots:hover {
        transform: none;
        margin-bottom: 40px;
    }

    .Screenshots img {
        transition: 0.3s all;
        margin: 0 6px;
    }

    .Screenshots img:hover {
        transform: scale(2.2);
        margin-left: 0;
        z-index: 999;
    }

    .Screenshots img::after {
        content: '';
        min-width: 70px
    }

    .Links {
        flex-direction: row;
    }
}

.TextArea {
    resize: none;
    width: 100%;
    height: 100%;
    padding: 0;
    border: none;
    flex: 1;
}

.Container {
    width: 100%;
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.TopBar {
    display: flex;
}

.TopBar div {
    padding: 2px 15px;
}
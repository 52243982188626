.Container {
    height: 100vh;
    width: 100vw;
    background-color: #000;
}

.ColorContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Text {
    position: absolute;
    font-size: 42px;
}

.flash {
    animation: flash 0.5s steps(1) infinite;
}

.glow {
    animation: glow 0.5s steps(1) infinite;
}

.glow-smooth {
    animation: glow 0.5s linear infinite;
}

@keyframes glow { 
    0% { background-color:red; }
    50% { background-color:orange; }
    100% { background-color:red; } 
}

@keyframes flash {
    50% {
        opacity: 0;
    }
}

* {
  font-family: "W95FA";
  color: #191919;
  font-size: 16px;
}

html {
  width: 100%;
  overflow: hidden;
}

html,
body,
#root,
#root > div {
  height: 100%;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

::selection {
  background: #000082;
  color: #ffffff;
}

::-moz-selection {
  background: #000082;
  color: #ffffff;
}

input[type="text"] {
  font-size: 16px;
  font-family: "W95FA";
}

.App {
  width: 100%;
  height: 100%;
}

.drag {
  cursor: grab;
}

.hidden {
  display: none !important;
}

.clickable {
  cursor: pointer;
  border: 2px solid transparent;
}

.noSelect {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.clickable:hover {
  border-top: 2px solid #eeeeee;
  border-left: 2px solid #eeeeee;
  border-bottom: 2px solid #b3b3b3;
  border-right: 2px solid #b3b3b3;
}

.semibold {
  font-weight: 600;
}

@font-face {
  font-family: "W95FA";
  src: local("W95FA"), url(./assets/fonts/w95fa.woff2) format("opentype");
  font-weight: normal;
  font-style: normal;
}

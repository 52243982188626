@import "/src/constants/colors";

.Window {
    min-width: 50px;
    min-height: 100px;
    background-color: $background;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 10%;
    left: 20%;
    border-top: 2px solid #f1f1f1;
    border-left: 2px solid #fbfbfb;
    border-right: 2px solid #3b3b3b;
    border-bottom: 2px solid #000000;
    overflow: auto;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.Window.max {
    transform: none !important;
    width: 100% !important;
    height: calc(100% - 44px) !important;
    top: 0;
    left: 0;
}

.TopBar {
    background-color: $highlight;
    height: 24px;
    width: 100%;
    color: white;
    font-weight: bold;
    display: flex;
    align-items: center;
    padding-left: 6px;
    padding-right: 6px;
    box-sizing: border-box;
}

.Window.resize {
    resize: both;
}

@media (max-width: 1200px) {
    .Window {
        top: 10%;
        left: 0;
    }
}
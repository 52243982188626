@import "/src/constants/colors";
@import "/src/constants/styles";

.TaskBar {
    height: 44px;
    width: 100%;
    display: flex;
    background-color: $default;
    border-top: 2px solid #e5eced;
    padding: 3px 10px;
    padding-bottom: 12px;
    box-sizing: border-box;
}

.Windows {
    display: flex;
    flex: 1;
    min-width: 0;
}

.Right {
    height: 100%;
}

.RightWrapper {
    border-top: 2px solid #8f8f8f;
    border-left: 2px solid #8f8f8f;
    border-bottom: 2px solid #e2e2e2;
    border-right: 2px solid #e2e2e2;
    height: 100%;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    align-items: center;
}

.Left {
    height: 100%;
    padding-right: 10px;
}

.StartButton {
    border-top: 2px solid #e2e2e2;
    border-left: 2px solid #e2e2e2;
    border-bottom: 2px solid #1c1c1c;
    border-right: 2px solid #1c1c1c;
    height: 100%;
    padding-left: 8px;
    padding-right: 8px;
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: larger;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    cursor: pointer;
}

.StartButton:active, .StartButton.selected {
    border-top: 2px solid #1c1c1c;
    border-left: 2px solid #1c1c1c;
    border-bottom: 2px solid #e2e2e2;
    border-right: 2px solid #e2e2e2;
}

.StartIcon {
    height: 24px;
    width: 24px;
    margin-right: 5px;
}
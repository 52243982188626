.Container {
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Cell {
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed #000;
    font-size: 20px;
    font-weight: bold;
}